.works-page {
  padding: 0 32px;
  padding-top: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 1919px) {
    padding-top: 160px;
  }

  @media (max-width: 1365px) {
    padding: 0 24px;
    padding-top: 145px;
  }

  @media (max-width: 767px) {
    padding: 0 16px;
    padding-top: 125px;
  }

  &-nav {
    position: relative;
    margin-bottom: 130px;

    @media (max-width: 1919px) {
      margin-bottom: 100px;
    }

    @media (max-width: 1365px) {
      margin-bottom: 90px;
    }

    button {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      font-weight: 700;
      font-size: 130px;
      line-height: 124px;
      color: #FFFFEB;
      text-transform: capitalize;

      @media (max-width: 1919px) {
        font-size: 100px;
        line-height: 100px;
      }

      @media (max-width: 1365px) {
        font-size: 80px;
        line-height: 80px;
      }

      @media (max-width: 767px) {
        font-size: 45px;
        line-height: 65px;
      }

      div {
        margin-right: 24px;
        width: 63px;
        height: 63px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #FFFFEB;
        border-radius: 50%;

        @media (max-width: 1919px) {
          width: 55px;
          height: 55px;
        }

        @media (max-width: 767px) {
          width: 45px;
          height: 45px;
        }

        svg {
          width: 25px;
          height: 15px;

          @media (max-width: 767px) {
            width: 26px;
          }
        }
      }
    }

    & > div {
      position: absolute;
      display: flex;
      align-items: center;
      height: 300px;
      width: 230px;
      border: 5px solid #FFFFEB;
      border-radius: 50px;
      overflow: hidden;
      background: #354EB7;
      z-index: 100;

      ul {
        position: absolute;
        width: 100%;
        padding: 16px;
        height: 80%;
        overflow-y: scroll;

        li {
          padding: 0 16px;
          font-weight: 700;
          font-size: 18px;
          line-height: 22px;
          color: #FFFFEB;
          cursor: pointer;

          &:not(:last-child) {
            margin-bottom: 20px;
          }
        }
      }
    }

  }

  &-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(7, 1fr);
    gap: 28px;

    @media (max-width: 1919px) {
      grid-template-columns: repeat(2, 1fr);
      gap: 40px 30px;
    }

    @media (max-width: 1365px) {
      gap: 24px;
    }

    @media (max-width: 767px) {
      grid-template-columns: repeat(1, 1fr);
      gap: 20px;
    }

    &-article {
      position: relative;
      min-height: 530px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-color: #FFFFEB;
      border: 5px solid #FFFFEB;
      border-radius: 50px;
      transition: box-shadow 0.3s ease-in-out;

      @media (max-width: 1919px) {
        min-height: 555px;
      }

      @media (max-width: 1365px) {
        box-shadow: 16px 68px 100px -10px rgba(0, 0, 0, 0.25);
        border-radius: 40px;
        min-height: 335px;
      }

      @media (max-width: 767px) {
        min-height: 310px;
      }

      @media (min-width: 1365px) {
        &:hover {
          box-shadow: 16px 68px 100px -10px rgba(0, 0, 0, 0.25);
        }
      }

      &-episodesCounter {
        position: absolute;
        top: 32px;
        left: 32px;
        padding: 10px 14px;
        font-weight: 700;
        font-size: 24px;
        line-height: 27px;
        color: #FFFFEB;
        background-color: #354EB7;
        border-radius: 1000px;
        text-transform: uppercase;

        @media (max-width: 1919px) {
          padding: 8px 12px;
          font-size: 18px;
          line-height: 22px;
        }

        @media (max-width: 1365px) {
          top: 16px;
          left: 20px;
          padding: 5px 8px;
          font-size: 13px;
          line-height: 16px;
        }
      }

      video {
        margin-bottom: 12px;
        width: 100%;
        height: 400px;
        object-fit: cover;
        border-radius: 50px;
        cursor: pointer;

        @media (max-width: 1919px) {
          height: 430px;
        }

        @media (max-width: 1365px) {
          border-radius: 40px;
          height: 235px;
        }
      }

      h3 {
        padding: 0 24px;
        margin-bottom: 10px;
        font-weight: 700;
        font-size: 43px;
        line-height: 50px;
        color: #354EB7;

        @media (max-width: 1365px) {
          padding: 0 16px;
          margin-bottom: 4px;
          font-size: 23px;
          line-height: 26px;
        }
      }

      &-stat {
        padding: 0 24px;
        padding-bottom: 24px;

        @media (max-width: 1365px) {
          padding: 0 16px;
          padding-bottom: 20px;
        }

        span {
          padding-left: 22px;
          font-weight: 700;
          font-size: 18px;
          line-height: 22px;
          color: #354EB7;
          background-position: left center;
          background-repeat: no-repeat;
          background-size: 18px 18px;

          @media (max-width: 1365px) {
            padding-left: 20px;
            font-size: 16px;
            line-height: 20px;
            background-size: 16px 16px;
          }

          &:nth-child(1) {
            background-image: url("../../assets/icons/views.svg");
          }

          &:nth-child(2) {
            background-image: url("../../assets/icons/likes.svg");
          }

          &:nth-child(3) {
            background-image: url("../../assets/icons/comments.svg");
          }

          &:not(:last-child) {
            margin-right: 16px;
          }
        }
      }
    }
  }
}
