.challenges-page {
  padding-top: 200px;
  min-height: 70vh;

  @media (max-width: 1919px) {
    padding-top: 165px;
  }

  @media (max-width: 1365px) {
    padding-top: 145px;
  }

  @media (max-width: 767px) {
    padding-top: 125px;
  }

  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h2 {
      margin-bottom: 130px;
      font-weight: 700;
      font-size: 130px;
      line-height: 124px;
      color: #FFFFEB;
      text-transform: capitalize;

      @media (max-width: 1919px) {
        margin-bottom: 100px;
        font-size: 100px;
        line-height: 100px;
      }

      @media (max-width: 1365px) {
        margin-bottom: 90px;
        font-size: 80px;
        line-height: 80px;
      }

      @media (max-width: 767px) {
        margin-bottom: 80px;
        font-size: 64px;
        line-height: 64px;
      }
    }
  }

  &-accordionBlock {
    width: 1232px;
    margin: 0 auto;

    @media (max-width: 1919px) {
      width: 768px;
    }

    @media (max-width: 1365px) {
      width: 720px;
    }

    @media (max-width: 767px) {
      width: 345px;
    }

    & > ul {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      & > li {
        width: 100%;
        background-color: #FFFFEB;
        border-radius: 35px;
        padding: 32px;

        @media (max-width: 767px) {
          padding: 16px;
        }

        &:not(:last-child) {
          margin-bottom: 32px;

          @media (max-width: 1365px) {
            margin-bottom: 24px;
          }

          @media (max-width: 767px) {
            margin-bottom: 16px;
          }
        }

        button {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          div {
            padding: 13px;
            padding-left: 134px;
            // background-image: url("../../assets/images/accordion-content.webp");
            background-size: 110px 110px;
            background-position: left center;
            background-repeat: no-repeat;

            @media (max-width: 1365px) {
              padding-left: 100px;
              background-size: 80px 80px;
            }

            @media (max-width: 767px) {
              padding-left: 55px;
              background-size: 45px 45px;
            }

            h6 {
              font-weight: 700;
              font-size: 60px;
              line-height: 60px;
              color: #354EB7;
              text-align: left;

              @media (max-width: 1919px) {
                font-size: 50px;
                line-height: 56px;
              }

              @media (max-width: 1365px) {
                font-size: 34px;
                line-height: 40px;
              }

              @media (max-width: 767px) {
                font-size: 20px;
                line-height: 24px;
              }
            }

            ul {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              gap: 24px;

              @media (max-width: 1365px) {
                gap: 16px;
              }

              @media (max-width: 767px) {
                gap: 8px;
              }

              li {
                padding-left: 36px;
                font-weight: 700;
                font-size: 28px;
                line-height: 30px;
                color: #354EB7;
                background-size: 28px 28px;
                background-repeat: no-repeat;
                background-position: left center;

                @media (max-width: 1365px) {
                  padding-left: 24px;
                  font-size: 20px;
                  line-height: 24px;
                  background-size: 20px 20px;
                }

                @media (max-width: 767px) {
                  padding-left: 20px;
                  font-size: 13px;
                  line-height: 16px;
                  background-size: 16px 16px;
                }

                &:nth-child(1) {
                  background-image: url("../../assets/icons/language.svg");
                }

                &:nth-child(2) {
                  background-image: url("../../assets/icons/people.svg");
                }

                &:nth-child(3) {
                  background-image: url("../../assets/icons/views.svg");
                }
              }
            }
          }

          span {
            width: 70px;
            height: 70px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background-color: transparent;
            transition: background-color 0.3s ease-in-out,
            border-color 0.3s ease-in-out;

            @media (max-width: 1919px) {
              width: 50px;
              height: 50px;
            }

            @media (max-width: 1365px) {
              width: 34px;
              height: 34px;
            }

            @media (max-width: 767px) {
              width: 24px;
              height: 24px;
            }

            svg {
              width: 70px;
              height: 70px;
            }

            @media (max-width: 1919px) {
              width: 50px;
              height: 50px;
            }

            @media (max-width: 1365px) {
              width: 34px;
              height: 34px;
            }

            @media (max-width: 767px) {
              width: 24px;
              height: 24px;
            }
          }
        }

        div {

          div {
            padding-top: 32px;
            font-weight: 700;
            font-size: 24px;
            line-height: 27px;
            color: #354EB7;
            transform-origin: top center;

            @media (max-width: 1365px) {
              padding-top: 24px;
              font-size: 20px;
              line-height: 24px;
            }

            @media (max-width: 767px) {
              padding-top: 16px;
              font-size: 16px;
              line-height: 20px;
            }

            ul {
              display: flex;
              flex-wrap: wrap;
              gap: 16px 0;
            }

            span,
            a {
              font-size: 28px;
              line-height: 30px;

              @media (max-width: 1919px) {
                font-size: 24px;
                line-height: 27px;
              }

              @media (max-width: 1365px) {
                font-size: 20px;
                line-height: 24px;
              }

              @media (max-width: 767px) {
                font-size: 16px;
                line-height: 20px;
              }
            }

            span {
              display: inline-block;
              margin-bottom: 32px;

              @media (max-width: 1365px) {
                margin-bottom: 24px;
              }

              @media (max-width: 767px) {
                margin-bottom: 16px;
              }
            }

            a {
              padding: 6px 10px;
              padding-right: 46px;
              background-color: rgba(53, 78, 183, 0.1);
              border-radius: 12px;
              background-image: url("../../assets/icons/link.svg");
              background-size: 28px 28px;
              background-repeat: no-repeat;
              background-position: calc(100% - 10px) 4px;

              @media (max-width: 1919px) {
                padding-right: 42px;
                background-position: calc(100% - 10px) 6px;
                background-size: 24px 24px;
              }

              @media (max-width: 1365px) {
                padding: 5px 8px;
                padding-right: 28px;
                background-position: calc(100% - 8px) 6px;
                background-size: 16px 16px;
              }

              &:not(:last-child) {
                margin-right: 24px;

                @media (max-width: 1919px) {
                  margin-right: 16px;
                }
              }
            }
          }
        }
      }
    }
  }
}
