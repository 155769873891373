.singleWork-page {

  &-hero {
    position: relative;
    height: calc(var(--vh, 1vh) * 100);
    margin-bottom: 120px;
    display: flex;
    align-items: flex-end;
    border: 5px solid #FFFFEB;
    box-shadow: 16px 68px 100px -10px rgba(0, 0, 0, 0.25);
    border-radius: 0px 0px 65px 65px;

    @media (max-width: 1365px) {
      border-radius: 0px 0px 50px 50px;
    }

    @media (max-width: 767px) {
      border-radius: 0px 0px 35px 35px;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background: #000;
      opacity: 0.5;
      border-radius: 0px 0px 65px 65px;

      @media (max-width: 1365px) {
        border-radius: 0px 0px 45px 45px;
      }

      @media (max-width: 767px) {
        border-radius: 0px 0px 35px 35px;
      }
    }

    &-wrapper {
      height: 100vh;
      margin-bottom: 120px;

      @media (max-width: 1919px) {
        margin-bottom: 100px;
      }

      @media (max-width: 1365px) {
        margin-bottom: 40px;
      }
    }

    video {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 0px 0px 65px 65px;

      @media (max-width: 1365px) {
        border-radius: 0px 0px 45px 45px;
      }

      @media (max-width: 767px) {
        border-radius: 0px 0px 35px 35px;
      }
    }

    &-container {
      height: 65%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      z-index: 100;
    }

    &-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      &-titleBlock {

        &-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
      }

      h1, h2 {
        margin-bottom: 24px;
        width: 100%;
        font-weight: 700;
        font-size: 130px;
        line-height: 124px;
        color: #FFFFEB;
        text-align: center;

        @media (max-width: 1919px) {
          font-size: 100px;
          line-height: 100px;
        }

        @media (max-width: 1365px) {
          margin-bottom: 16px;
          font-size: 80px;
          line-height: 80px;
        }

        @media (max-width: 767px) {
          display: inline-block;
          font-size: 45px;
          line-height: 50px;
          text-align: center;
        }
      }

      &-stat {
        display: flex;

        @media (max-width: 767px) {
          flex-direction: column;
        }

        & > div {
          margin-right: 24px;
          background-position: left center;
          background-repeat: no-repeat;
          background-size: 28px 28px;

          @media (max-width: 1919px) {
            background-size: 24px 24px;
          }

          @media (max-width: 1365px) {
            background-size: 20px 20px;
          }

          @media (max-width: 767px) {
            margin-bottom: 12px;
          }

          &:nth-child(1) {
            background-image: url("../../assets/icons/views-white.svg");
          }
  
          &:nth-child(2) {
            background-image: url("../../assets/icons/likes-white.svg");
          }
  
          &:nth-child(3) {
            background-image: url("../../assets/icons/comments-white.svg");
          }
          
          & > span {
            font-weight: 700;
            font-size: 28px;
            line-height: 30px;
            color: #FFFFEB;
            
            @media (max-width: 1919px) {
              font-size: 24px;
              line-height: 27px;
            }
      
            @media (max-width: 1365px) {
              font-size: 20px;
              line-height: 24px;
            }
      
            &:first-child {
              padding-left: 36px;
      
              @media (max-width: 1919px) {
                padding-left: 32px;
              }
      
              @media (max-width: 1365px) {
                padding-left: 24px;
              }
            }
          }
        }
      }
    }
        
    button {
      margin-bottom: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 50px;
      border: 1.5px solid #FFFFEB;
      border-radius: 50%;

      @media (max-width: 1365px) {
        width: 40px;
        height: 40px;
      }

      svg {
        width: 29.5px;
        height: 29.5px;

        @media (max-width: 1365px) {
          width: 23.5px;
          height: 23.5px;
        }
      }
    }
  }

  &-content {
    padding: 0 32px;

    @media (max-width: 1365px) {
      padding: 0 24px;
    }

    @media (max-width: 767px) {
      padding: 0 16px;
    }

    &-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-tabs {
      margin-bottom: 50px;
      display: flex;

      @media (max-width: 1365px) {
        margin-bottom: 40px;
      }

      @media (max-width: 767px) {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      & > li {

        &:not(:last-child) {
          margin-right: 50px;

          @media (max-width: 1365px) {
            margin-right: 32px;
          }

          @media (max-width: 767px) {
            margin-right: 0;
          }
        }

        button {
          font-weight: 700;
          font-size: 40px;
          line-height: 50px;
          color: #FFFFEB;
          text-transform: uppercase;
          opacity: 0.5;

          &.active {
            opacity: 1;
          }

          @media (max-width: 1919px) {
            font-size: 34px;
            line-height: 40px;
          }

          @media (max-width: 1365px) {
            font-size: 22px;
            line-height: 32px;
          }
        }
      }
    }

    &-aboutCartoon {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      max-width: 915px;
      margin-bottom: 100px;

      @media (max-width: 1919px) {
        max-width: 768px;
      }

      @media (max-width: 1365px) {
        max-width: 475px;
      }

      @media (max-width: 767px) {
        max-width: 345px;
        margin-bottom: 80px;
      }

      h4 {
        margin-bottom: 40px;
        font-weight: 700;
        font-size: 60px;
        line-height: 60px;
        color: #FFFFEB;

        @media (max-width: 1919px) {
          font-size: 50px;
          line-height: 56px;
        }

        @media (max-width: 1365px) {
          margin-bottom: 30px;
          font-size: 34px;
          line-height: 40px;
        }

        @media (max-width: 767px) {
          font-size: 40px;
          line-height: 44px;
        }
      }

      p {
        font-weight: 400;
        font-size: 24px;
        line-height: 32px;
        color: #FFFFEB;

        @media (max-width: 1365px) {
          font-size: 20px;
          line-height: 30px;
        }

        @media (max-width: 767px) {
          font-size: 18px;
          line-height: 28px;
        }
      }
    }

    &-grid {
      margin-bottom: 200px;
      width: 100%;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 30px;

      @media (max-width: 1919px) {
        grid-template-columns: repeat(2, 1fr);
        gap: 40px 30px;
      }

      @media (max-width: 1365px) {
        margin-bottom: 100px;
        gap: 24px;
      }

      @media (max-width: 767px) {
        margin-bottom: 80px;
        grid-template-columns: repeat(1, 1fr);
        gap: 20px;
      }

      &-article {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: #FFFFEB;
        border: 5px solid #FFFFEB;
        min-height: 530px;
        border-radius: 50px;
        transition: box-shadow 0.3s ease-in-out;
        cursor: default;

        @media (max-width: 1919px) {
          min-height: 555px;
        }

        @media (max-width: 1365px) {
          box-shadow: 16px 68px 100px -10px rgba(0, 0, 0, 0.25);
          border-radius: 40px;
          min-height: 335px;
        }

        @media (max-width: 767px) {
          min-height: 310px;
        }

        @media (min-width: 1365px) {
          &:hover {
            box-shadow: 16px 68px 100px -10px rgba(0, 0, 0, 0.25);
          }
        }

        &::after {
          content: "";
          position: absolute;
          top: 150px;
          left: 250px;
          width: 100px;
          height: 100px;
          background-image: url("../../assets/icons/play.svg");
          background-repeat: no-repeat;
          background-position: 33px 27px;
          background-size: 42px 46px;
          background-color: #354EB7;
          border-radius: 50%;
          pointer-events: none;
          transition: transform 0.25s ease-in-out;

          @media (max-width: 1365px) {
            top: 88px;
            left: 144px;
            width: 60px;
            height: 60px;
            background-position: 19.5px 16px;
            background-size: 25px 27px;
          }
        }

        &-without-play::after {
          display: none;
          background-image: none;
        }

        h3 {
          padding: 0 24px;
          margin-bottom: 10px;
          font-weight: 700;
          font-size: 44px;
          line-height: 44px;
          color: #354EB7;

          @media (max-width: 1919px) {
            margin-bottom: 4px;
            font-size: 47px;
          }

          @media (max-width: 1365px) {
            padding: 0 16px;
            font-size: 25px;
            line-height: 40px;
          }
        }

        img {
          margin-bottom: 12px;
          width: 100%;
          height: 400px;
          object-fit: cover;
          border-radius: 50px;
          cursor: pointer;

          @media (max-width: 1919px) {
            height: 430px;
          }

          @media (max-width: 1365px) {
            margin-bottom: 0;
            border-radius: 40px;
            height: 235px;
          }
        }

        &-timeCounter {
          position: absolute;
          top: 32px;
          left: 32px;
          padding: 10px 14px;
          padding-left: 44px;
          font-weight: 700;
          font-size: 24px;
          line-height: 27px;
          color: #FFFFEB;
          background-color: #354EB7;
          border-radius: 1000px;
          background-image: url("../../assets/icons/clocks.svg");
          background-repeat: no-repeat;
          background-position: 14px 12.5px;
          background-size: 22px 22px;

          @media (max-width: 1919px) {
            padding: 8px 12px;
            padding-left: 34px;
            font-size: 18px;
            line-height: 22px;
            background-size: 18px 18px;
            background-position: 12px 10px;
          }

          @media (max-width: 1365px) {
            top: 16px;
            left: 20px;
            padding: 5px 8px;
            padding-left: 28px;
            font-size: 13px;
            line-height: 16px;
            background-size: 16px 16px;
            background-position: 8px 5px;
          }
        }

        &-stat {
          padding: 0 24px;
          padding-bottom: 24px;

          @media (max-width: 1365px) {
            padding: 0 16px;
            padding-bottom: 20px;
          }

          span {
            padding-left: 22px;
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            color: #354EB7;
            background-position: left center;
            background-repeat: no-repeat;
            background-size: 18px 18px;

            @media (max-width: 1365px) {
              padding-left: 20px;
              font-size: 16px;
              line-height: 20px;
              background-size: 16px 16px;
            }

            &:nth-child(1) {
              background-image: url("../../assets/icons/views.svg");
            }

            &:nth-child(2) {
              background-image: url("../../assets/icons/likes.svg");
            }

            &:nth-child(3) {
              background-image: url("../../assets/icons/comments.svg");
            }

            &:not(:last-child) {
              margin-right: 16px;
            }
          }
        }
      }
    }
  }

  &-nextWork {
    padding: 0 32px;

    @media (max-width: 1365px) {
      padding: 0 24px;
    }

    @media (max-width: 767px) {
      padding: 0 16px;
    }

    h3 {
      margin-bottom: 60px;
      font-weight: 700;
      font-size: 60px;
      line-height: 60px;
      color: #FFFFEB;
      text-transform: uppercase;

      @media (max-width: 1919px) {
        font-size: 50px;
        line-height: 56px;
      }

      @media (max-width: 1365px) {
        margin-bottom: 40px;
        font-size: 34px;
        line-height: 40px;
      }

      @media (max-width: 767px) {
        margin-bottom: 24px;
      }
    }

    &-article {
      min-height: 770px;
      max-height: 770px;
      position: relative;
      display: flex;
      background-color: #FFFFEB;
      border-radius: 50px;
      overflow: hidden;
      transition: box-shadow 0.3s ease-in-out;

      @media (max-width: 1919px) {
        min-height: 540px;
        max-height: 540px;
      }

      @media (max-width: 1365px) {
        min-height: 335px;
        border-radius: 40px;
        box-shadow: 16px 68px 100px -10px rgba(0, 0, 0, 0.25);
      }

      @media (max-width: 767px) {
        min-height: 310px;
        flex-direction: column-reverse;
      }

      @media (min-width: 1365px) {
        &:hover {
          box-shadow: 16px 68px 100px -10px rgba(0, 0, 0, 0.25);
        }
      }

      &-left {
        width: 40%;
        padding-left: 40px;
        padding-bottom: 40px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;

        @media (max-width: 1365px) {
          padding-left: 20px;
          padding-bottom: 16px;
          width: 30%;
        }

        @media (max-width: 767px) {
          width: 100%;
        }

        & > span {
          position: absolute;
          top: 40px;
          left: 40px;
          padding: 10px 14px;
          color: #FFFFEB;
          background-color: #354EB7;
          font-weight: 700;
          font-size: 24px;
          line-height: 27px;
          border-radius: 1000px;
          text-transform: uppercase;

          @media (max-width: 1919px) {
            padding: 8px 12px;
            font-size: 18px;
            line-height: 22px;
          }

          @media (max-width: 1365px) {
            top: 16px;
            left: 20px;
            padding: 5px 8px;
            font-size: 13px;
            line-height: 16px;
          }
        }

        h4 {
          margin-bottom: 8px;
          font-weight: 700;
          font-size: 90px;
          line-height: 88px;
          color: #354EB7;

          @media (max-width: 1919px) {
            margin-bottom: 4px;
            font-size: 68px;
            line-height: 74px;
          }

          @media (max-width: 1365px) {
            margin-bottom: 12px;
            font-size: 32px;
            line-height: 40px;
          }

          @media (max-width: 767px) {
            margin-bottom: 4px;
          }
        }

        div {
          display: flex;

          @media (max-width: 1365px) {
            flex-direction: column;
          }

          @media (max-width: 767px) {
            flex-direction: row;
          }

          span {
            padding-left: 36px;
            font-weight: 700;
            font-size: 28px;
            line-height: 30px;
            color: #354EB7;
            background-position: left center;
            background-repeat: no-repeat;
            background-size: 28px 28px;

            @media (max-width: 1919px) {
              padding-left: 32px;
              font-size: 24px;
              line-height: 27px;
              background-size: 24px 24px;
            }

            @media (max-width: 1365px) {
              padding-left: 20px;
              font-size: 16px;
              line-height: 20px;
              background-size: 16px 16px;
            }

            &:nth-child(1) {
              background-image: url("../../assets/icons/views.svg");
            }

            &:nth-child(2) {
              background-image: url("../../assets/icons/likes.svg");
            }

            &:nth-child(3) {
              background-image: url("../../assets/icons/comments.svg");
            }

            &:not(:last-child) {
              margin-right: 16px;

              @media (max-width: 1365px) {
                margin-right: 0;
                margin-bottom: 8px;
              }

              @media (max-width: 767px) {
                margin-right: 16px;
                margin-bottom: 0px;
              }
            }
          }
        }
      }

      &-right {
        width: 60%;

        @media (max-width: 1365px) {
          width: 70%;
        }

        @media (max-width: 767px) {
          width: 100%;
        }

        img {
          object-fit: cover;
          height: 100%;
          width: 100%;
          border: 5px solid #FFFFEB;
          border-radius: 50px;
          cursor: pointer;

          @media (max-width: 1365px) {
            border-radius: 40px;
          }
          @media (max-width: 767px) {
            height: 230px;
          }
        }
      }
    }
  }
}
.singleWork-page-content-grid-article:hover.singleWork-page-content-grid-article::after {
  transform: scale(1.25);
}
