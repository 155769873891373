.about-page {

  &-hero {
    padding-top: 215px;

    @media (max-width: 1919px) {
      padding-top: 165px;
    }

    @media (max-width: 1365px) {
      padding-top: 145px;
    }

    @media (max-width: 767px) {
      padding-top: 125px;
    }

    &-container {
      padding: 0 32px;

      @media (max-width: 1365px) {
        padding: 0 24px;
      }

      @media (max-width: 767px) {
        padding: 0 16px;
      }
    }

    p {
      width: 99%;
      font-weight: 700;
      font-size: 110px;
      line-height: 124px;
      color: #FFFFEB;
      text-align: center;

      @media (max-width: 1919px) {
        font-size: 75px;
        line-height: 100px;
      }

      @media (max-width: 1365px) {
        font-size: 42px;
        line-height: 80px;
      }

      @media (max-width: 767px) {
        width: 100%;
        font-size: 35px;
        line-height: 44px;
      }

      img {
        margin: 0 7px;
        margin-bottom: -7px;
        width: 110px;
        height: 110px;
        object-fit: cover;
        border-radius: 50%;

        @media (max-width: 1919px) {
          width: 80px;
          height: 80px;
          margin: 0 4px;
          margin-bottom: -15px;
        }

        @media (max-width: 1365px) {
          margin: 0 5px;
          margin-bottom: -10px;
          width: 50px;
          height: 50px;
        }

        @media (max-width: 767px) {
          width: 40px;
          height: 40px;
        }
      }
    }
  }

  &-raw {
    padding-top: 130px;
    padding-bottom: 200px;

    @media (max-width: 1919px) {
      padding-top: 100px;
    }

    @media (max-width: 1365px) {
      padding-top: 90px;
      padding-bottom: 150px;
    }

    @media (max-width: 767px) {
      padding-top: 80px;
      padding-bottom: 80px;
    }

    & .parallax {
      overflow: visible;
    }

    span {
      align-items: center;

      img {
        margin-right: 40px;
        border-radius: 50px;
        box-shadow: 16px 68px 100px -10px rgba(0, 0, 0, 0.25);

        @media (max-width: 767px) {
          border-radius: 40px;
          margin-right: 30px;
        }
      }

      img:nth-child(1) {
        width: 390px;
        height: 365px;
      }

      img:nth-child(2) {
        width: 390px;
        height: 490px;
      }

      img:nth-child(3) {
        width: 450px;
        height: 330px;
      }

      img:nth-child(4) {
        width: 390px;
        height: 390px;
      }

      img:nth-child(5) {
        width: 390px;
        height: 490px;
      }

      img:nth-child(6) {
        width: 450px;
        height: 330px;
      }

      img:nth-child(7) {
        width: 390px;
        height: 490px;
      }
    }
  }

  &-portfolio {
    margin-bottom: 220px;

    @media (max-width: 1365px) {
      margin-bottom: 150px;
    }

    @media (max-width: 767px) {
      margin-bottom: 80px;
    }

    &-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    img {
      margin-bottom: 70px;
      width: 456px;
      height: 300px;

      @media (max-width: 1365px) {
        margin-bottom: 45px;
        width: 395px;
        height: 260px;
      }

      @media (max-width: 767px) {
        margin-bottom: 24px;
        width: 245px;
        height: 160px;
      }
    }

    p {
      width: 54%;
      font-weight: 700;
      font-size: 68px;
      line-height: 74px;
      color: #FFFFEB;
      text-align: center;

      @media (max-width: 1919px) {
        width: 75%;
      }

      @media (max-width: 1365px) {
        font-size: 54px;
        line-height: 60px;
      }

      @media (max-width: 767px) {
        font-size: 40px;
        line-height: 44px;
      }

      span {
        position: relative;
        margin-right: 10px;
        margin-left: 10px;
        color: #76E7EE;

        // &::after {
        //   content: "";
        //   position: absolute;
        //   top: -22px;
        //   left: -22px;
        //   right: -22px;
        //   bottom: -22px;
        //   background-image: url("../../assets/icons/450.svg");
        //   background-repeat: no-repeat;
        //   background-size: 100% 100%;
        //   background-position: center;

        //   @media (max-width: 1365px) {
        //     background-size: 122px 76px;
        //   }

        //   @media (max-width: 767px) {
        //     background-size: 90.5px 55px;
        //   }
        // }
      }
    }
  }

  &-total {
    position: relative;
    height: calc(var(--vh, 1vh) * 100);
    display: flex;
    align-items: flex-end;
    border: 5px solid #FFFFEB;
    box-shadow: 16px 68px 100px -10px rgba(0, 0, 0, 0.25);
    border-radius: 50px;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background: #000;
      opacity: 0.6;
      border-radius: 50px;
    }

    &-wrapper {
      height: 100vh;
      margin-bottom: 200px;

      @media (max-width: 1365px) {
        margin-bottom: 40px;
      }
    }

    video {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50px;
    }

    &-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 55%;
      padding-bottom: 75px;
      z-index: 100;

      @media (max-width: 767px) {
        padding-bottom: 35px;
      }
    }

    &-top {

      h6 {
        font-weight: 700;
        font-size: 40px;
        line-height: 50px;
        color: #FFFFEB;
        text-align: center;

        @media (max-width: 1919px) {
          font-size: 34px;
          line-height: 40px;
        }

        @media (max-width: 1365px) {
          font-size: 28px;
          line-height: 32px;
        }
      }

      span {
        font-weight: 700;
        font-size: 130px;
        line-height: 124px;
        color: #FFFFEB;

        @media (max-width: 1919px) {
          font-size: 68px;
          line-height: 74px;
        }

        @media (max-width: 1365px) {
          font-size: 80px;
          line-height: 80px;
        }

        @media (max-width: 767px) {
          font-size: 52px;
          line-height: 56px;
        }
      }

      & > span:not(:last-child) {
        margin-right: 30px;

        @media (max-width: 1919px) {
          margin-right: 20px;
        }

        @media (max-width: 1365px) {
          margin-right: 15px;
        }

        @media (max-width: 767px) {
          margin-right: 10px;
        }
      }
    }

    &-bottom {
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        width: 42%;
        font-weight: 700;
        font-size: 40px;
        line-height: 50px;
        color: #FFFFEB;
        text-align: center;

        @media (max-width: 1919px) {
          width: 50%;
          font-size: 34px;
          line-height: 40px;
        }

        @media (max-width: 1365px) {
          font-size: 28px;
          line-height: 32px;
        }

        @media (max-width: 767px) {
          width: 80%;
          font-size: 20px;
          line-height: 24px;
        }
      }
    }
  }

  &-people {
    margin-bottom: 200px;

    @media (max-width: 1365px) {
      margin-bottom: 150px;
    }

    @media (max-width: 767px) {
      margin-bottom: 80px;
    }

    &-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    h2 {
      margin-bottom: 60px;
      font-weight: 700;
      font-size: 60px;
      line-height: 60px;
      color: #FFFFEB;
      text-transform: uppercase;

      @media (max-width: 1919px) {
        font-size: 50px;
        line-height: 56px;
      }

      @media (max-width: 1365px) {
        margin-bottom: 40px;
        font-size: 34px;
        line-height: 40px;
      }

      @media (max-width: 767px) {
        margin-bottom: 24px;
      }
    }

    &-grid {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(2, 1fr);
      gap: 28px;

      @media (max-width: 1919px) {
        gap: 50px;
      }

      @media (max-width: 1365px) {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 24px;
      }

      @media (max-width: 767px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }

      article {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
          margin-bottom: 20px;
          width: 442px;
          height: 442px;
          border-radius: 50%;

          @media (max-width: 1919px) {
            width: 236px;
            height: 236px;
          }

          @media (max-width: 1365px) {
            width: 224px;
            height: 224px;
          }

          @media (max-width: 767px) {
            width: 162px;
            height: 162px;
          }
        }

        h5 {
          font-weight: 700;
          font-size: 40px;
          line-height: 50px;
          color: #FFFFEB;
          text-align: center;

          @media (max-width: 1919px) {
            font-size: 24px;
            line-height: 27px;
          }

          @media (max-width: 1365px) {
            font-size: 18px;
            line-height: 24px;
          }
        }

        span {
          font-weight: 700;
          font-size: 28px;
          line-height: 30px;
          color: #FFFFEB;
          opacity: 0.5;
          text-align: center;

          @media (max-width: 1919px) {
            font-size: 18px;
            line-height: 22px;
          }

          @media (max-width: 1365px) {
            font-size: 16px;
            line-height: 20px;
          }
        }
      }
    }
  }

  &-works {

    h2 {
      margin-bottom: 60px;
      font-weight: 700;
      font-size: 60px;
      line-height: 60px;
      color: #FFFFEB;
      text-transform: uppercase;

      @media (max-width: 1919px) {
        font-size: 50px;
        line-height: 56px;
      }

      @media (max-width: 1365px) {
        margin-bottom: 40px;
        font-size: 34px;
        line-height: 40px;
      }

      @media (max-width: 767px) {
        margin-bottom: 24px;
      }
    }

    &-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 32px;
      padding-bottom: 100px;

      @media (max-width: 1919px) {
        padding-bottom: 60px;
      }

      @media (max-width: 1365px) {
        padding: 0 24px;
        padding-bottom: 40px;
      }

      @media (max-width: 767px) {
        padding: 0 16px;
        padding-bottom: 40px;
      }
    }

    &-btn {
      display: inline-block;
      padding: 14px 20px;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: #354EB7;
      background-color: #FFFFEB;
      border-radius: 1000px;
      text-transform: uppercase;
      cursor: pointer;

      @media (max-width: 1365px) {
        padding: 10px 17px;
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
}
