.main-page {

  &-hero {
    position: relative;
    height: calc(var(--vh, 1vh) * 100);
    display: flex;
    align-items: flex-end;
    border: 5px solid #FFFFEB;
    box-shadow: 16px 68px 100px -10px rgba(0, 0, 0, 0.25);
    border-radius: 0px 0px 70px 70px;

    @media (max-width: 1919px) {
      border-radius: 0px 0px 45px 45px;
    }

    @media (max-width: 767px) {
      margin-bottom: 80px;
      border-radius: 0px 0px 35px 35px;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background: #354EB7;
      opacity: 0.6;
      border-radius: 0px 0px 65px 65px;

      @media (max-width: 1919px) {
        border-radius: 0px 0px 45px 45px;
      }

      @media (max-width: 767px) {
        border-radius: 0px 0px 35px 35px;
      }
    }

    &-wrapper {
      height: 100vh;
      margin-bottom: 120px;

      @media (max-width: 1919px) {
        margin-bottom: 100px;
      }

      @media (max-width: 1365px) {
        margin-bottom: 40px;
      }
    }

    video {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 0px 0px 70px 70px;

      @media (max-width: 1919px) {
        border-radius: 0px 0px 45px 45px;
      }

      @media (max-width: 767px) {
        border-radius: 0px 0px 35px 35px;
      }
    }

    &-container {
      height: 75%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      z-index: 100;

      @media (max-width: 1919px) {
        height: 70%;
      }
    }

    h1, h2, h3 {
      font-weight: 700;
      font-size: 130px;
      line-height: 140px;
      color: #FFFFEB;
      text-align: center;

      @media (max-width: 1919px) {
        font-size: 75px;
        line-height: 81px;
      }

      @media (max-width: 1365px) {
        font-size: 80px;
      }

      @media (max-width: 767px) {
        font-size: 45px;
        line-height: 45px;
      }
    }

    &-footer {
      padding-bottom: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      p {
        margin-bottom: 24px;
        width: 70%;
        font-weight: 700;
        font-size: 24px;
        line-height: 27px;
        color: #FFFFEB;
        text-align: center;

        @media (max-width: 1365px) {
          font-size: 20px;
          line-height: 24px;
        }

        @media (max-width: 767px) {
          width: 60%;
        }
      }

      &-btnsContainer {
        display: flex;

        & > span {
          margin-right: 16px;
          padding: 12.5px 18.5px;
          font-weight: 700;
          font-size: 18px;
          line-height: 22px;
          color: #FFFFEB;
          border: 1.5px solid #FFFFEB;
          border-radius: 1000px;
          text-transform: uppercase;
          cursor: pointer;
          z-index: 100;

          @media (max-width: 1365px) {
            padding: 10px 17px;
            font-size: 16px;
            line-height: 20px;
          }
        }

        button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 50px;
          height: 50px;
          border: 1.5px solid #FFFFEB;
          border-radius: 50%;

          @media (max-width: 1365px) {
            width: 40px;
            height: 40px;
          }

          svg {
            width: 29.5px;
            height: 29.5px;

            @media (max-width: 1365px) {
              width: 23.5px;
              height: 23.5px;
            }
          }
        }
      }
    }

  }

  &-works {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 32px;
    padding-bottom: 200px;

    @media (max-width: 1365px) {
      padding: 0 24px;
      padding-bottom: 100px;
    }

    @media (max-width: 767px) {
      padding: 0 16px;
      padding-bottom: 80px;
    }

    h2 {
      margin-bottom: 50px;
      font-weight: 700;
      font-size: 40px;
      line-height: 50px;
      color: #FFFFEB;
      text-transform: uppercase;

      @media (max-width: 1365px) {
        margin-bottom: 40px;
        font-size: 28px;
        line-height: 32px;
      }
    }

    &-grid {
      margin-bottom: 60px;
      width: 100%;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 1fr);
      gap: 30px;

      @media (max-width: 1919px) {
        grid-template-columns: repeat(2, 1fr);
        gap: 40px 30px;
      }

      @media (max-width: 1365px) {
        margin-bottom: 40px;
        gap: 24px;
      }

      @media (max-width: 767px) {
        grid-template-columns: repeat(1, 1fr);
        gap: 20px;
      }

      &-article {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        min-height: 530px;
        background-color: #FFFFEB;
        border: 5px solid #FFFFEB;
        border-radius: 50px;
        transition: box-shadow 0.3s ease-in-out;

        @media (max-width: 1919px) {
          min-height: 555px;
        }

        @media (max-width: 1365px) {
          box-shadow: 16px 68px 100px -10px rgba(0, 0, 0, 0.25);
          border-radius: 40px;
          min-height: 335px;
        }

        @media (max-width: 767px) {
          min-height: 310px;
        }

        @media (min-width: 1365px) {
          &:hover {
            box-shadow: 16px 68px 100px -10px rgba(0, 0, 0, 0.25);
          }
        }

        &-episodesCounter {
          position: absolute;
          top: 32px;
          left: 32px;
          padding: 10px 14px;
          font-weight: 700;
          font-size: 24px;
          line-height: 27px;
          color: #FFFFEB;
          background-color: #354EB7;
          border-radius: 1000px;
          text-transform: uppercase;

          @media (max-width: 1919px) {
            padding: 8px 12px;
            font-size: 18px;
            line-height: 22px;
          }

          @media (max-width: 1365px) {
            top: 16px;
            left: 22px;
            padding: 5px 8px;
            font-size: 13px;
            line-height: 16px;
          }
        }

        video {
          margin-bottom: 12px;
          width: 100%;
          height: 400px;
          object-fit: cover;
          border-radius: 50px;
          cursor: pointer;

          @media (max-width: 1919px) {
            height: 430px;
          }

          @media (max-width: 1365px) {
            border-radius: 40px;
            height: 235px;
          }
        }

        h3 {
          padding: 0 24px;
          margin-bottom: 10px;
          font-weight: 700;
          font-size: 53px;
          line-height: 60px;
          color: #354EB7;

          @media (max-width: 1919px) {
            font-size: 50px;
            line-height: 56px;
          }

          @media (max-width: 1365px) {
            padding: 0 16px;
            font-size: 32px;
            line-height: 40px;
          }

          @media (max-width: 767px) {
            font-size: 30px;
          }
        }

        &-stat {
          padding: 0 24px;
          padding-bottom: 24px;

          @media (max-width: 1365px) {
            padding: 0 16px;
            padding-bottom: 20px;
          }

          span {
            padding-left: 22px;
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            color: #354EB7;
            background-position: left center;
            background-repeat: no-repeat;
            background-size: 18px 18px;

            @media (max-width: 1365px) {
              padding-left: 20px;
              font-size: 16px;
              line-height: 20px;
              background-size: 16px 16px;
            }

            &:nth-child(1) {
              background-image: url("../../assets/icons/views.svg");
            }

            &:nth-child(2) {
              background-image: url("../../assets/icons/likes.svg");
            }

            &:nth-child(3) {
              background-image: url("../../assets/icons/comments.svg");
            }

            &:not(:last-child) {
              margin-right: 16px;
            }
          }
        }
      }
    }

    & > span {
      padding: 14px 20px;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: #354EB7;
      background-color: #FFFFEB;
      border-radius: 1000px;
      text-transform: uppercase;
      cursor: pointer;

      @media (max-width: 1365px) {
        padding: 10px 17px;
        font-size: 16px;
        line-height: 20px;
      }
    }
  }

  &-wasFounded {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 90px;
    padding-right: 55px;
    background-color: #354EB7;
    border: 5px solid #FFFFEB;
    border-radius: 70px;

    @media (max-width: 1919px) {
      padding: 70px;
    }

    @media (max-width: 1365px) {
      padding: 32px 22px;
      flex-direction: column-reverse;
      justify-content: center;
    }

    &-container {
      padding: 0 32px;

      @media (max-width: 1365px) {
        padding: 0 24px;
      }

      @media (max-width: 767px) {
        padding: 0 16px;
      }
    }

    &-left {

      @media (max-width: 1365px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 75%;
      }

      @media (max-width: 767px) {
        width: 100%;
      }

      p {
        margin-bottom: 32px;
        font-weight: 700;
        font-size: 70px;
        line-height: 88px;
        color: #FFFFEB;

        @media (max-width: 1919px) {
          margin-bottom: 24px;
          font-size: 60px;
          line-height: 74px;
        }

        @media (max-width: 1365px) {
          margin-bottom: 32px;
          width: 100%;
          font-size: 51px;
          line-height: 60px;
          text-align: center;
        }

        @media (max-width: 767px) {
          font-size: 30px;
          line-height: 45px;
        }

        img {
          margin: 0 7px;
          margin-bottom: -7px;
          width: 80px;
          height: 80px;
          object-fit: cover;
          border-radius: 50%;

          @media (max-width: 1919px) {
            width: 50px;
            height: 50px;
          }

          @media (max-width: 1365px) {
          }

          @media (max-width: 767px) {
            margin: 0 10px;
            margin-bottom: -7px;
            width: 40px;
            height: 40px;
          }
        }
      }

      & > span {
        display: inline-block;
        padding: 12.5px 18.5px;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: #FFFFEB;
        border: 1.5px solid #FFFFEB;
        border-radius: 1000px;
        text-transform: uppercase;
        cursor: pointer;
      }
    }

    &-right {
      align-self: flex-end;

      @media (max-width: 1365px) {
        align-self: center;
      }

      @media (max-width: 767px) {
        margin-bottom: 40px;
      }

      img {
        width: 511px;
        height: 336px;
        transition: transform 0.3s ease-in-out;

        @media (max-width: 1919px) {
          width: 488px;
          height: 305px;
        }

        @media (max-width: 1365px) {
          width: 380px;
          height: 250px;
        }

        @media (max-width: 767px) {
          width: 275px;
          height: 180px;
        }
      }
    }

    &:hover .main-page-wasFounded-right img {
      @media (min-width: 1024px) {
        transform: scale(1.15);
      }
    }
  }
}
