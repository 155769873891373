.preloader-container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #354EB7;
  opacity: 1;
  touch-action: none;
  transition: opacity 1s ease-in-out;
  overflow: hidden;
  z-index: 9999;

  &-block {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      margin-bottom: 40px;
      width: 380px;
      height: 250px;

      @media (max-width: 1919px) {
        width: 182px;
        height: 120px;
      }
    }

    div {
      position: relative;
      width: 380px;
      height: 5px;
      background-color: rgb(255, 255, 235, 0.5);
      border-radius: 16px;

      @media (max-width: 1919px) {
        width: 182px;
      }

      span {
        position: absolute;
        top: 0;
        left: 0;
        display: inline-block;
        height: 5px;
        width: 100%;
        opacity: 1;
        background-color: #FFFFEB;
        border-radius: 16px;
        animation: preloader-anim 4.5s normal ease;
        z-index: 1;
      }
    }
  }
}

.hiddenContainer {
  opacity: 0;
}

@keyframes preloader-anim {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}
