.header {
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  z-index: 250;
  border-top: 5px solid #FFFFEB;

  &-nav {
    padding: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1;

    @media (max-width: 1919px) {
      padding: 24px 32px;
    }

    @media (max-width: 1365px) {
      padding: 15px 20px;
    }

    &-mobile {
      display: none;

      @media (max-width: 767px) {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: calc(var(--vh, 1vh) * 100);
        z-index: 500;
        touch-action: none;
      }

      &-background {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        // background-image: url("../../assets/images/bg.png");
      }

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        outline: none;
        border: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        cursor: pointer;
        position: absolute;
        top: 18px;
        right: 15px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #FFFFEB;
        pointer-events: all !important;

        svg {

        }
      }

      ul,
      li {
        margin: 0;
        padding: 0;
      }

      ul {
        padding: 25px;
        position: absolute;
        top: 250px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
      }

      li {
        list-style: none;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        cursor: pointer;
        font-weight: 700;
        font-size: 40px;
        line-height: 44px;
        color: #FFFFEB;
        text-transform: uppercase;
      }
    }


    &-left {
      display: flex;

      @media (max-width: 767px) {
        display: none;
      }

      li {

        &:not(:last-child) {
          margin-right: 32px;

          @media (max-width: 1919px) {
            margin-right: 18px;
          }
        }

        a {
          font-weight: 700;
          font-size: 18px;
          line-height: 22px;
          color: #FFFFEB;
          text-transform: uppercase;

          @media (max-width: 1365px) {
            font-size: 16px;
            line-height: 20px;
          }
        }
      }
    }

    &-right {

      button {
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: #FFFFEB;

        @media (max-width: 1365px) {
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
  }

  &-logoContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 0;

    @media (max-width: 1365px) {
      pointer-events: none;
    }

    div {
      width: 208px;
      height: 74px;
      background-image: url("../../assets/icons/logotype.svg");
      background-size: 169px 46px;
      background-position: center;
      background-repeat: no-repeat;
      background-color: #FFFFEB;
      box-shadow: 4px 22px 30px -10px rgba(0, 0, 0, 0.25);
      border-radius: 0px 0px 16px 16px;
      cursor: pointer;

      @media (max-width: 1919px) {
        width: 180px;
        height: 64px;
        background-size: 146px 40px;
      }

      @media (max-width: 1365px) {
        pointer-events: all;
        width: 140px;
        height: 50px;
        background-size: 114px 31px;
      }

      @media (max-width: 767px) {
        width: 123px;
        height: 44px;
        background-size: 100px 27.5px;
      }
    }
  }
}
