*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  box-sizing: border-box;
  line-height: 1.15; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
}
body {
  position: relative;
  margin: 0;
  box-sizing: border-box;
  line-height: 1.6;
  font-family: 'Anek Bangla', sans-serif;
  overscroll-behavior-y: none;
  background-color: #354EB7;
  overflow-x: hidden;
}
main {
  display: block;
}
h1 {
  font-size: 2em;
}
hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}
pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}
a {
  background-color: transparent;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}
abbr[title] {
  border-bottom: none; /* 1 */
  text-decoration: underline; /* 2 */
  text-decoration: underline dotted; /* 2 */
}
b,
strong {
  font-weight: bolder;
}
code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
img {
  border-style: none;
}
button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}
input {
  padding: 0;
  background-color: transparent;
  border: none;
}
input:focus-visible {
  outline: none;
}
button,
input { /* 1 */
  overflow: visible;
}
button {
  cursor: pointer;
  padding: 0;
  background-color: transparent;
  border: none;
}
button,
select { /* 1 */
  text-transform: none;
}
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}
fieldset {
  padding: 0.35em 0.75em 0.625em;
}
legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  padding: 0; /* 3 */
  white-space: normal; /* 1 */
}
progress {
  vertical-align: baseline;
}
textarea {
  overflow: auto;
}
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}
::selection {
  background: gray;
  color: white;
}
details {
  display: block;
}
summary {
  display: list-item;
}
template {
  display: none;
}
[hidden] {
  display: none;
}
ul {
 margin: 0;
 padding: 0;
 list-style-type: none;
}
h1,h2,h3,h4,h5,h6,p {
  margin: 0;
}
::-webkit-scrollbar {
  height: 7px;
  width: 7px;
  background: #354EB7;
  -webkit-border-radius: 1ex;
}
::-webkit-scrollbar-thumb {
  background: #FFFFEB;
  -webkit-border-radius: 1ex;
}

::-webkit-scrollbar-corner {
  background: #fff3;
}

@media (max-width: 1599px) {
  ::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    background: #354EB7;
    -webkit-border-radius: 1ex;
  }

  ::-webkit-scrollbar-thumb {
    background: #FFFFEB;
    -webkit-border-radius: 1ex;
  }

  ::-webkit-scrollbar-corner {
    background: #fff3;
  }
}

.main {
  overflow: hidden;
  will-change: transform;
  background-color: #354EB7;
  background-image: url("./assets/images/bg.webp");
}

.container {
  margin: 0 auto;
  max-width: 1920px;
}

@media (max-width: 1919px) {
  .container {
    max-width: 1366px;
  }
}

@media (max-width: 1365px) {
  .container {
    max-width: 768px;
  }
}

@media (max-width: 767px) {
  .container {
    max-width: 375px;
  }
}

.parallax {
  display: flex;
  overflow: hidden;
  will-change: transform;
  letter-spacing: -2px;
  margin: 0;
  white-space: nowrap;
  flex-wrap: nowrap;
}

.parallax .scroller {
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
}

.scroller > span {
  display: flex;
}

.scroller > span > span,
.scroller > span > a {
  margin-right: 40px;
}
@media (min-width: 1024px) {
  .anim-text {
    display: block;
    overflow: hidden;
  }

  .anim-text:hover span {
    transform: translateY(-125%);
  }

  .anim-text:hover span::after {
    transform: translateY(125%);
  }

  .anim-text span {
    display: inline-block;
    position: relative;
    transition: transform 1.2s cubic-bezier(0.19, 1, 0.22, 1);
  }

  .anim-text span::after {
    content: attr(data-text);
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateY(150%);
    transition: transform 1.2s cubic-bezier(0.19, 1, 0.22, 1);
  }
}
.privacy-screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #354EB7;
  z-index: 10000;
}

.counter:not(:last-child) {
  margin-right: 6px;
}