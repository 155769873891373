.footer {
  margin-top: 100px;
  background: #FFFFEB;
  border: 5px solid #354EB7;
  border-radius: 70px 70px 0px 0px;

  @media (max-width: 1919px) {
    border-radius: 50px 50px 0px 0px;
  }

  &-top {
    margin-bottom: 50px;
    padding: 32px;
    padding-bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 1365px) {
      margin-bottom: 35px;
    }

    &-right {

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 44px;
        height: 44px;
        border: 1.5px solid #354EB7;
        border-radius: 50%;

        svg {
          width: 26px;
          height: 26px;
        }
      }
    }

    &-left {

      ul {
        display: flex;

        li {

          &:not(:last-child) {
            margin-right: 8px;
          }

          a {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 44px;
            height: 44px;
            border: 1.5px solid #354EB7;
            border-radius: 50%;

            svg {
              width: 24px;
              height: 24px;
            }
          }
        }
      }
    }
  }

  &-middle {
    margin-bottom: 80px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 1919px) {
      margin-bottom: 70px;
    }

    @media (max-width: 1365px) {
      margin-bottom: 60px;
    }

    img {
      width: 292px;
      height: 80px;

      @media (max-width: 1365px) {
        width: 270px;
        height: 74px;
      }

      @media (max-width: 767px) {
        width: 220px;
        height: 60px;
      }
    }
  }

  &-bottom {
    padding-top: 24px;
    padding-bottom: 24px;
    font-weight: 700;
    font-size: 24px;
    line-height: 27px;
    color: #354EB7;

    @media (max-width: 1919px) {
      font-size: 18px;
      line-height: 22px;
    }

    @media (max-width: 1365px) {
      padding-bottom: 20px;
      font-size: 16px;
      line-height: 20px;
    }

    a {
      padding-left: 33px;
      padding-right: 1px;
      background-image: url("../../assets/icons/footer-circle.svg");
      background-size: 7px 7px;
      background-repeat: no-repeat;
      background-position: center left;
    }
  }
}
